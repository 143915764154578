import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import FullContainer from "../components/common/FullContainer"
import terminal from "../images/terminal.svg"

const Image = styled.img`
  height: 400px;

  @media (max-width: 1200px) {
    height: 300px;
  }

  @media (max-width: 700px) {
    height: 200px;
  }

  @media (max-width: 600px) {
    height: 100px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
`

const Button = () => {
  const open = () => {
    window.open("/courses", "_self")
  }
  const Wrapper = styled.button`
    font-size: 18px;
    text-align: center;
    background-color: #4caf50;
    color: white;
    border: none;
    height: 50px;
    width: 150px;
    border-radius: 12px;
    margin-top: 2em;
    cursor: pointer;
  `
  return <Wrapper onClick={open}>Explore Courses</Wrapper>
}

const Index = () => {
  return (
    <>
      <Layout>
        <SEO title="Home" />
        <FullContainer>
          <div>
            <h1>Are you an employee who wants to upgrade their IT skills?</h1>
            <p>This website is a great resource for you.</p>
            <h2>START EXPLORING NOW!</h2>
          </div>
          <ButtonContainer>
            <Button />
          </ButtonContainer>
          <Image src={terminal}></Image>
        </FullContainer>
      </Layout>
    </>
  )
}

export default Index
